<template>
  <div class="register-success">
    <div class="header">
      <div class="row justify-content-center align-items-center">
        <img
          src="@/assets/logo_named.png"
          alt="logo"
          class="logo"
        />
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div>
            <AppIcon name="checkmark_filled" :size="80" class="text-success" />
          </div>
          <h1 class="mt-4">SUCCESSFULLY SIGNED UP!</h1>
          <h2 >Please check your email for the verification.</h2>
          <router-link to="login" class="mt-5">
            <span class="text-primary go-to-login">Go to Login Page</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';

export default {
  name: 'RegisterSuccess',

  components: {
    AppIcon,
  },
};
</script>

<style lang="scss" scoped>

.register-success {
  background-color: #F1F1F1;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'header'
    'content';
  
  min-height: 100%;
}

h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 52px;

  text-align: center;
  letter-spacing: 0.8px;
  color: #282828;

}

h2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #282828;

  margin-left: 2px;
  margin-top: 3px;
}

.header {
  grid-area: header;
}

.container {
  max-width: 1290px;
  padding: 0;
  grid-area: content;
  margin-top: 170px;
}

.logo {
  width: 172px;
  height: 163px;
  margin-top: 60px;
}

a {
  text-decoration: none;
}

.go-to-login {
  margin-top: 59px;
}
</style>
